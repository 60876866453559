.Carousel{
    position: relative;
    height: 60vh;
    display: flex;
    justify-content: center;
}

/* Fullscreen mode */
.CarouselFull{
    position:fixed;
    height:100%;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
}
.CarouselFull .backdrop{
    position:fixed;
    background: black;
    height: 100%;
    width: 100%;
    z-index: -3;
    opacity: 0.8;
}

/* Buttons */
.rightArrow, .leftArrow, .expand{
    position: absolute;
    top:50%;
    font-size: 2rem;
    color: var(--font-secondary);
    z-index: 10;
    cursor: pointer;
    user-select: none;
    opacity: 55%;
}
.CarouselFull .rightArrow, .CarouselFull .leftArrow, .CarouselFull .expand{
    position: absolute;
    top:50%;
    font-size: 2rem;
    color: var(--font-color);
    z-index: 10;
    cursor: pointer;
    user-select: none;
    opacity: 100%;
}
.rightArrow:hover, .leftArrow:hover, .expand:hover{
    transition: 0.25s;
    opacity: 100%;
}
.CarouselFull .rightArrow:hover, .CarouselFull .leftArrow:hover, .CarouselFull .expand:hover{
    opacity: 80%;
}
.rightArrow{
    right: 1rem;
}
.leftArrow{
    left: 1rem;
}
.expand{
    font-size: 2rem;
    top: 1rem;
    right: 1rem;
}
.CarouselFull .expand{
    top: 15%;
    right: 1rem;
}


.slide{
    opacity: 0.95;
    transition-duration: 1s ease-in;
    transform: translate(25px);
}

.slideActive{
    height: auto;
    opacity: 1;
    transition-duration: 1s;
    display: flex;
    /* align-items: center; */
    /* transform: scale(1.08); */
}
.Carousel .slideActive{
    height: inherit;
    align-items: baseline;
}
.slideActive .image{
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    padding: 2rem 0;
}


.CarouselFull .slideActive{
    /* transform: scale(1.08); */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: calc(100% - 2rem);
}
/* Thumbnails */
.thumbnails{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}
.thumbnails img{
    position: relative;
    height: 75px;
    width: 75px;
}
.thumbnail{

}
.thumbnailActive{
    border: 2px solid var(--programming-color);
}

/* IPAD  */
@media (min-width: 46rem) {
    .Carousel{
        /* position: relative; */
        /* display: flex; */
        /* align-items: center; */
        height: 80vh;
        justify-content: center;
    }

    /* Fullscreen mode */
    .CarouselFull{
        position:fixed;
        height:100%;
        width: 100%;
        top: 0;
        left: 0;
        /* display:block; */
    }
    
    /* Buttons */
    .rightArrow, .leftArrow, .expand{
        position: absolute;
        top:50%;
        font-size: 3rem;
        color: var(--font-secondary);
        z-index: 10;
        cursor: pointer;
        user-select: none;
        opacity: 55%;
    }
    .CarouselFull .rightArrow, .CarouselFull .leftArrow, .CarouselFull .expand{
        position: absolute;
        top:50%;
        font-size: 3rem;
        color: var(--font-color);
        z-index: 10;
        cursor: pointer;
        user-select: none;
        opacity: 100%;
    }
    .rightArrow{
        right: 3rem;
    }
    .leftArrow{
        left: 3rem;
    }
    .expand{
        font-size: 2rem;
        top: 20%;
        right: 3rem;
    }
    .CarouselFull .expand{
        top: 15%;
        right: 3rem;
    }

    /* Transitions */
    .slide{
        opacity: 0.95;
        transition-duration: 1s ease-in;
        transform: translate(25px);
    }

    .Carousel .slideActive{
        height: inherit;
        align-items: baseline;
    }
    .slideActive .image{
        max-width: 100%;
        max-height: 100%;
        padding: 4rem 0;
    }
    .CarouselFull .slideActive{
        margin: 0;
        /* max-width: 1500px; */
    }    
    .CarouselFull .slideActive{
        justify-content: center;
        margin: auto;
        width: calc(100% - 10rem);
    }
}

@media (min-width: 68rem) {
    /* Working At Full Res */
    .Carousel .slideActive{
        height: inherit;
        align-items: baseline;
    }
    /* Working Fullscreen At Full Res */
    .CarouselFull .image{
        padding: 0 10rem;
    }
}
