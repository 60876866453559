.body{
    /* width: 100%; */
    padding: 0;
    margin-right: 5px;
    margin-left: 5px;
    color:var(--font-secondary);
    height: 100%;
}

/* Images + buttons */
.left{
    width: 100%;
    /* height: 100%; */
    padding-left: 0.85rem;
    padding-right: 0.85rem;
}


/* Gallery */
.images{
    margin-top: 2rem;
    margin-bottom: 2rem;
    transition: all 0.6s ease;
}

/* First Image */
/* .images img{
    width: 100%;
} */

.projectName{
    text-align: center;
    display: block;
}

.description .projectName{
    display: none;
}

.description{
    width: 100%;
    float:none;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
}
.summary{
    padding-bottom: 2rem;
}


/* IPAD  */
@media (min-width: 46rem) {
    .body{
        min-width: 46rem;
        width: 100%;
        padding-right: 15px;
        padding-left:15px;
        margin-right: auto;
        margin-left: auto;
        color:var(--font-secondary);
        height: 100%;
    }
    .left{
        width: 50%;
        float: left;
        height: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .description{
        width: 50%;
        float: left;
        padding-left: 15px;
        padding-right: 15px;
        text-align:left;
    }
    .images{
        /* max-height: 100vh; */
        /* height: 100%; */
    }
    .projectName{
        display: none;
    }
    .description .projectName{
        text-align: left;
        display: block;
    }
}

/* LARGE */
@media (min-width: 68rem) {
    .body{
        min-width: 68rem;
        width: 80%;
        padding-right: 15px;
        padding-left:15px;
        margin-right: auto;
        margin-left: auto;
        color:var(--font-secondary);
        height: 100%;
    }
}
