* {
    box-sizing: border-box;
}

:root{
    --design-color: #1755A6;
    --animation-color: #37A647;
    --programming-color:#F29F05;
    --red-color: #F23838;
    --blue-color:#1B62BF;
    --font-color: #fff;
    --font-secondary: #444;
    --purple-color: #975BF5;
    --breaker-color: #e7e7e7;
    --ipad-size: 46rem;
    --desktop-size:68rem;
    /* --twitter-color: ; */
}
  
html, body {
    margin: 0;
    padding:0;
    height: 100%;
    width:100%;
    font-family: "Raleway", helvetica, arial, sans-serif;    
    font-weight: 400;
    color: var(--font-color);
    font-size: 1rem;
    /* Scroll bars */
    scrollbar-width: none;  /*  Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
}

body::-webkit-scrollbar {
    display: none; /*Chrome Scroll bar */
}  

#root, .App{
    width: 100%;
    height: 100%;
}

.App{
    
}

.AppDark{
    /* --design-color: #000000;
    --animation-color: #000000;
    --programming-color:#000000;
    --red-color: #000000;
    --blue-color:#000000;
    --font-color: #000000;
    --font-secondary: #000000;
    --purple-color: #000000;
    --breaker-color: #000000; */
}

.wrapper{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 1;
}

.wrapperBlur{
    z-index: -1;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(0.45rem);
    opacity: .6;
    transition: filter 0.35s ease, -webkit-filter 0.35s ease, opacity 0.375s ease-out;
}


li{
    list-style: none;
}

.centered {
    margin: 3rem auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page{
    min-height: 70vh;
}

.shade{
    background: linear-gradient(to bottom, rgba(0,0,0,.7) 0%, rgba(255,255,255,0) 100%);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 2;
}

.breaker{
    border:0;
    margin: 1.4rem auto;
    width: 60px;
    border-top: 2px solid;
    border-color: var(--breaker-color);
}
  
.focused {
    font-size: 3rem;
    font-weight: bold;
    color: white;
}
  
.secondary{
    text-align: center;
    font-size: 2em;
    color: var(--font-secondary);
    text-transform: uppercase;
}

.btn{
    text-decoration: none;
    color: var(--font-color);
    font:inherit;
    font-weight: 400;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    vertical-align: middle;
    padding: 0.85rem 1.4rem;
    border: 0;
    margin: 0.25rem;
    border-radius: 4px;
    background: var(--font-secondary);
    cursor: pointer;
    transition: all 1s ease;
    /* background: var(--programming-color); */

}

.btn:hover{
    border: 0;
    background: var(--programming-color);
    transition: all 0.2s ease;
}

.btn-programming{

}

.btn-selected {
    text-decoration: none;
    background-color: var(--programming-color);
    color: white;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    border: 1px solid var(--programming-color);
    cursor: pointer;
}

  
/* .btn--flat {
    cursor: pointer;
    font: inherit;
    color: teal;
    border: none;
    background-color: none;
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
} */
  
/* .btn--flat:hover,
.btn--flat:active {
    background-color: teal;
    color: white;
} */


/* IPAD  */
@media (min-width: 46rem) {
    .breaker{
        border:0;
        margin: 1.6rem auto;
        width: 60px;
        border-top: 2px solid;
        border-color: var(--breaker-color);
    }
    
    .btn{
        text-decoration: none;
        color: var(--font-color);
        font:inherit;
        font-weight: 400;
        font-size: 0.75rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        vertical-align: middle;
        padding: 1rem 2rem;
        border: 0;
        margin: 10px;
        border-radius: 6px;
        background: #444;
        cursor: pointer;
    }
    
    .btn-selected {
        text-decoration: none;
        background-color: rgba(20, 139, 120, 0.178);
        color: white;
        border-radius: 4px;
        padding: 0.75rem 1.5rem;
        border: 1px solid teal;
        cursor: pointer;
    }
    
}

/* LARGE */
@media (min-width: 68rem) {
    .breaker{
        margin: 40px auto;
        width: 60px;
        border-top: 2px solid;
        border-color: var(--breaker-color);
    }
}

