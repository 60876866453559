.list{
    position: relative;
    margin: 0 auto;
    padding: 1rem 0 4rem;
    height: 100%;
    /* max-width: 2000px; */
    /* max-height: 1000px;  */
    /* list-style: none; */
    text-align: center;
}

.list::before{
    content: "";
    background-image: url("../../images/global/breaker-bottom.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center -40px;
    position: absolute;
    top: -20px;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 90px;
    pointer-events: none;    
}

.filters{
    text-align: center;
}

@media (min-width: 46rem) {
    .list{
        position: relative;
        margin: 0 auto;
        padding: 1em 0 4em;
        max-width: 2000px;
        height: 100%;
        max-height: 1000px; 
        list-style: none;
        text-align: center;
        /* background: chartreuse; */
    }
    .list::before{
        content: "";
        background-image: url("../../images/global/breaker-bottom.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center -40px;
        position: absolute;
        top: -20px;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 90px;    
    }
    .filters{
        text-align: center;
    }
}


@media (min-width: 68rem) {

}