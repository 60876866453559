/* Top bar for when over the white BG */
.nav{
    margin: 0;
    display: block;
    position: fixed;
    box-sizing: border-box;
    font-size: 1rem;
    top: 0px;
    z-index: 8;
    width: 100%;
}

/* Top bar for when over the white BG */
/* todo scroll add bg */
.nav::before{
    content: "";
    background-image: url("../../images/global/breaker-bottom.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    position: absolute;
    top: 0px;
    z-index: 0;
    width: 100%;
    height: 155px;
    opacity: 0.4;
    transition: 0.2s;
    display:none;
}

/* Three bars + Curtis Bucciol Logo */
.mainNav{
    height: 100%;
    /* z-index: 10; */

}

/* Home Button // Curtis Bucciol */
.logo::before{
    content: "Curtis B";
}

/* Home Button // Curtis Bucciol */
.logo{
    /* display: inline-block; */
    /* position: relative; */
    /* margin-left: 20%;     */
    /* vertical-align: middle; */
    /* height: 50px; */
    /* min-width: 200px;
    width: 200px; */
    display: flex;
    position: fixed;
    right:0;
    top: 0;
    margin: 3rem;
    padding: 1rem;
    text-align: center;
    height: 75px;

    color: var(--font-color);
    font-size: 2rem;
    text-decoration: none;
    font-weight: 400;

}

/* Main div */
.mobileNav{
    /* position: fixed;
    transition: transform 0.35s ease, opacity 0.35s ease, visibility 0.35s;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    height: 100%;
    align-items: center;
    opacity: 1;
    background: rgba(36, 41, 67, 0.9);
    height: 100vh;
    width: 100%;
    z-index: 8;
    overflow: hidden; */
    height: 0;
    visibility: hidden;
    top: 0;
    background: rgba(36, 41, 67, 0.0);
    opacity: 0;
    display: hidden;
}

.mobileNavOpen{
    position: fixed;
    transition: transform 0.35s ease, opacity 0.35s ease, visibility 0.35s;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    height: 100%;
    align-items: center;
    opacity: 1;
    background: rgba(36, 41, 67, 0.9);
    background: rgba(23, 85, 166,0.9);
    /* BG color #103B73 */
    background:rgba(16, 59, 115, 0.9);
    height: 100vh;
    width: 100%;
    z-index: 8;
    overflow: hidden;
    opacity: 1;
    visibility: visible;
    display: flex;

}

.menuButton{
    position: fixed;
    top: 0;
    left: 0;
    margin: 3rem;
    padding: 1rem;
    background: none;
    border: none;
    fill: white;
    cursor: pointer;
    z-index: 10;
}

/* Button to open the menu */
.openMenuButton{
    padding: 1rem;
    vertical-align: middle;
    background: none;
    border: none;
    fill: white;
    cursor: pointer;
}

/* Close mobile menu */
.closeMenuButton{
    content: "";
    display: inline-block;
    /* background-repeat: no-repeat;
    background-position: center; */
    /* transition: opacity 0.2s ease-in-out; */
    /* background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px' viewBox='0 0 20 20' zoomAndPan='disable'%3E%3Cstyle%3Eline %7B stroke: %23ffffff%3B stroke-width: 2%3B %7D%3C/style%3E%3Cline x1='0' y1='0' x2='20' y2='20' /%3E%3Cline x1='20' y1='0' x2='0' y2='20' /%3E%3C/svg%3E"); */
}

.closeMenuButton svg{
    transition: all 1s ease;
}

.rectangle1, .rectangle2, .rectangle3{
    transition: all 0.5s ease;
}

.rectangle1Close{
    transition: all 0.5s ease;
    transform:rotate(45deg) translate(12.5px, -10px);
}

.rectangle2Close{
    transition: all 0.5s ease;
    opacity: 0;
}

.rectangle3Close{
    transition: all 0.5s ease;
    transform:rotate(-45deg) translate(-47.5px,-10px);
}


/* Container for the center */
.mobileInner{
    display:none;
    max-width: 100%;
    max-height: 100vh;
    overflow: auto;
    text-align: center;
    width: 18rem;
}
.mobileInnerOpen{
    display: block;
}

/* ul of Links for other pages */
.mobilePages{
    text-align: center;
    border: 0;
    margin: 0 0 1rem 0;
    padding: 0;
}

/* Slightly lowered */
.mobilePages:first-child{
    margin-top: 0.25rem;
}

/* Links breaker lines */
.mobilePages a{
    border: 0;
    line-height: 4rem;
    letter-spacing: 0.25rem;
    font-weight: 500;
    font-size: 1.2rem;
    border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    border-right-color: currentcolor;
    border-right-style: none;
    border-right-width: 0px;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-left-color: currentcolor;
    border-left-style: none;
    border-left-width: 0px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 10px;
    border-top: solid 1px rgba(212, 212, 255, 0.1);
    border-top-color: rgba(212, 212, 255, 0.1);
    border-top-style: solid;
    border-top-width: 1px;
    display: block;
}

/* No breaker on the top of the list */
.mobilePages li:first-child >  a{
    border-top: 0 ;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
}

/* SVG border animation */
.svg{
    position: absolute;
    display: flex;
}

.shape{
    fill: transparent;
    stroke-dasharray: 140 540;
    stroke-dashoffset: -450;
    stroke-width: 4px;
    stroke: var(--design-color);
    stroke: transparent;
}

@keyframes draw {
    0% {
      stroke-dasharray: 140 540;
      stroke-dashoffset: -450;
      stroke-width: 8px;
    }
    100% {
      stroke-dasharray: 760;
      stroke-dashoffset: 0;
      stroke-width: 2px;
    }
}

.svgWrapper:hover .shape {
    -webkit-animation: 0.45s draw linear forwards;
    animation: 0.45s draw linear forwards;
    stroke: var(--programming-color);
}


.pages{
    text-align: left;
    padding-left: 0;
    position: relative;
    width: 38%;
    /* float: left; */
    display: none;
}


.pages li{
    display: inline-block;
    list-style-type: none;
    margin: 0 20px;
    padding: 10px 0 16px 0;
    text-align: center;
    position: relative;
    text-shadow:  0px 0px 10px rgba(0,0,0,.2);
}


.link {
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--font-color);
    text-decoration: none;
}

.link:hover{
    opacity: 0.4;
    transition-duration: 0.4s;
}

.svgWrapper .link{
    opacity: 0.8;
}

.svgWrapper .link:hover{
    background: rgba(23,85,166,0.2);
    opacity: 1;   
    text-shadow: 0.75px 0.75px 1px rgba(242,159,5,0.6);
    box-shadow: 0.75px 0.75px 1px rgba(242,159,5,0.6);
}


/* .pages li:hover{
    border-bottom: 1px solid rgba(255,255,255,0.8);
} */


.socials{
    /* text-align: right; */
    padding-left: 20%;
    position: relative;
    display: none;
    /* height: 45px; */
    /* float: right; */

    display: inline-block;
}

.socials li{
    display: none;
    list-style-type: none;
    margin-left: 15px;
    padding: 0;
    text-align: center;
    position: relative;
}

.mobileNav .socials{
    padding-left: 0;
}

.mobileNav .socials li{
    display: inline-block;
    align-items: center;
    margin: 0 15px;
}


/* IPAD  */
@media (min-width: 46rem) {
    .logo::before{
        content: "Curtis Bucciol";
    }
    .logo{
        /* display: inline-block;
        vertical-align:middle;
        margin-left: 5%;    
        top: 0;
        height: 55px;
        min-width: 200px;
        width: 24%; */
        /* position: relative; */
        /* float: left; */
    }
    
    .pages{
        display: inline-block;
        width: auto;
        padding-left: 25px;
    }
    
    .socials li{
        display: none;
    }
    
}

/* LARGE */
@media (min-width: 68rem) {
    .logo::before{
        content: "Curtis Bucciol";
    }
    .logo{
        /* margin-left: 0;     */
    }
    .nav::before{
        height: 175px;
    }
    .pages{
        display: inline-block;
        width: 38%;
        padding-left: 0;
    }
    .socials{
        display: inline-block;
    }
    .socials li{
        display: inline-block;
    }


}

