.auth {
    margin: 2rem auto;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    width: 25rem;
    border-radius: 8px;
    padding: 1rem;
    text-align: center;
    background-color: #f4f0fa;
}
  
.control {
    margin-bottom: 0.5rem;
} 
  
.control label {
    display: block;
    color: #616161;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}
  
.control input {
    display: block;
    width: 20rem;
    margin: auto;
    border-radius: 4px;
    padding: 0.25rem;
    border: 1px solid #ccc;
}
.control textarea{
    resize: none;
    width: 20rem;
    height: 10rem;
}

  
.invalid label {
    display: block;
    color: #616161;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}
  
.invalid input {
    display: block;
    width: 20rem;
    margin: auto;
    border-radius: 4px;
    padding: 0.25rem;
    border: 1px solid #ccc;
}

.invalid p {
    color: var(--red-color);
    margin: 0;
    padding: 0.15rem;
}

.errorMessage{
    height: 1rem;
}