.page{
    width: 100%;
    height: 100%;
    /* padding-left: 5%;
    padding-right: 5%; */
    /* margin-right: auto;
    margin-left: auto; */
    text-align: center;
    color:var(--font-secondary);
    transition: all 1s ease;
}

.page h3{
    text-align: center;
    font-weight: 700;
    /* letter-spacing: -1px; */
    text-transform: uppercase;
    margin-top: 25px;
    margin-bottom: 15px;
    font-size: 1.4rem;
    line-height: 1.1;
}

.page p{
    text-align: center;
    margin: 0 0 10px;
}


.profile{
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    transition: all 0.8s ease;
}

.profile ul{
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0 0 10px;

}

.profile li{
    padding: 0.5rem;
}

.profilePicture{
    border-radius: 50%;
    width: 240px;
    vertical-align: middle;
    transition: all 0.4s ease;

}


.bio{
    padding: 40px;
    width:100%;
    transition: all .4s ease;
}


/* IPAD  */
@media (min-width: 46rem) {
    .page{
        width: 100%;
        height: 100%;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
    }
    .bio{
        margin-top: 1.6rem;
        padding: 0;
        width:75%;
        padding-right: 1rem;
        padding-left: 1rem;
        float:left;
    }
    .profile{
        margin-top: 1.6rem;
        width: 25%;
        float:left;
        padding-left: 15px;
        padding-right: 15px;
    }

    .profilePicture{
        border-radius: 50%;
        width: 140px;
    }
   

}


/* LARGE */
@media (min-width: 68rem) {
    .bio{
        margin-top: 1.6rem;
        margin: 40px auto;
        padding-left: 40px;
        padding-right: 40px;
        width:75%;
        float:left;
    }
    .page{
        width: 100%;
        height: 100%;
        width:1170px;
        padding-left: 5%;
        padding-right: 5%;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        color:var(--font-secondary);
    }
    .profile{
        margin-top: 1.6rem;
        width: 25%;
        float:left;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
    }

    .profilePicture{
        border-radius: 50%;
        width: 180px;
        vertical-align: middle;
    }
    

}

