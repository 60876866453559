/* Common Style */

/* Square */
.item{
	position: relative;
	float: left;
	overflow: hidden;
	min-width: 180px;
	max-width: 800px;
	max-height: 600px;
	width: 50%;
	background: var(--blue-color);
	text-align: center;
	cursor: pointer;
    list-style: none;
    transition: all 0.6s ease;
}

/* Content inside box */
.item figure{
    margin: 0;
}

/* Image */
.item figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}

/* Text */
.item figure figcaption {
	color: var(--font-color);
	text-transform: uppercase;
	font-size: 0.95rem;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.item figure figcaption::before,
.item figure figcaption::after {
	pointer-events: none;
}

.item figure figcaption,
.item figure figcaption > .link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.item figure h2 {
    word-spacing: 0.15em;
	font-weight: 300;
}


.item figure p {
	letter-spacing: 1px;
	font-size: 70%;
}

/*---------------*/
/***** Sadie *****/
/*---------------*/

/* Make sure the link is the Covering the whole area */
.link {
    display: block;
    height: 100%;
}

.item figcaption::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: -webkit-linear-gradient(top, rgba(72,76,97,0) 0%, rgba(72,76,97,0.8) 75%);
	background: linear-gradient(to bottom, rgba(72,76,97,0) 0%, rgba(72,76,97,0.8) 75%);
	content: '';
	opacity: 0;
	-webkit-transform: translate3d(0,50%,0);
	transform: translate3d(0,50%,0);
}
/* todo: sort out %'s  */
.programming figcaption::before {
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) -5%, #F29F05 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) -5%, #F29F05 100%);
}

.design figcaption::before {
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) -5%, #1755A6 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) -5%, #1755A6 100%);
}
.animation figcaption::before {
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) -5%, #37A647 100%);
	background: linear-gradient(to bottom, rgba(0,0,0,0) -5%, #37A647 100%);
}

.item h2 {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
    opacity: 75%;
	-webkit-transition: -webkit-transform 0.35s, color 0.35s;
	transition: transform 0.35s, color 0.35s;
	-webkit-transform: translate3d(0,-50%,0);
	transform: translate3d(0,-50%,0);
}

.item figcaption::before,
.item p {
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
}

.item p {
	position: absolute;
	bottom: 1rem;
	left: 0;
	width: 100%;
	opacity: 0;
	-webkit-transform: translate3d(0,10px,0);
	transform: translate3d(0,10px,0);
}

.item:hover h2 {
    opacity: 100%;
	-webkit-transform: translate3d(0,-50%,0) translate3d(0,-20px,0);
	transform: translate3d(0,-50%,0) translate3d(0,-20px,0);
}

.item:hover figcaption::before ,
.item:hover p,
.link:focus figcaption::before,
.link:focus p{
    opacity: 1;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}


/* IPAD  */
@media (min-width: 46rem) {
    .item figure figcaption {
        text-transform: uppercase;
        font-size: 1.15rem;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    .item{
        overflow: hidden;    
        min-width: 180px;
        max-width: 800px;
        max-height: 600px;
        width: 50%;
        background: var(--blue-color);
    }

    .item:hover h2 {
        opacity: 100%;
        -webkit-transform: translate3d(0,-50%,0) translate3d(0,-30px,0);
        transform: translate3d(0,-50%,0) translate3d(0,-30px,0);
    }    
    
}

/* LARGE */
@media (min-width: 68rem) {
    .item figure figcaption {
        /* padding: 2em; */
        color: #fff;
        text-transform: uppercase;
        font-size: 1.25rem;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .item:hover h2 {
        opacity: 100%;
        -webkit-transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
        transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
    }   
    
    .item{
        overflow: hidden;    
        min-width: 320px;
        max-width: 800px;
        max-height: 600px;
        width: 33.33%;
        background: var(--blue-color);
    }


}

