:root
{
    --border-radius: 10%;
    --icon-size: 1rem;
}

.socialMediaIcon
{
    height: inherit;
    width: inherit;
    border-radius: var(--border-radius);
    fill: var(--font-color);
}

.socialMediaIconDark
{
    height: inherit;
    width: inherit;
    border-radius: var(--border-radius);
    fill: var(--font-secondary);

}

.socialMediaIcon:hover{
    transition-duration: 0.4s;
}

.youtube:hover{
    fill: var(--red-color);
}

.twitter:hover{
    filter: brightness(2);
    fill: var(--blue-color);
}

.twitch:hover{
    fill: var(--purple-color);
}



.link
{
    height: var(--icon-size);
    width: var(--icon-size);
    display:inline-block;
    margin: 0 1rem;
    background-size: 95%;
    border-radius: var(--border-radius);
    /* background-color: black; */


}