.spinner {
    display: inline-block;
    width: 8rem;
    height: 8rem;
    text-align: center;
  }
  .spinner:after {
    content: ' ';
    display: block;
    width: 6rem;
    height: 6rem;
    margin: 0 auto;
    border-radius: 50%;
    border: 0.5rem solid var(--blue-color);
    border-color: var(--blue-color) transparent var(--blue-color) transparent; 
    animation: spinner 1.2s linear infinite;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  