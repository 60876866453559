.banner{
    position: relative;
    width: 100%;
    height: 25%;
    top: 0;
	left: 0;
	z-index: 0;
	overflow: hidden;
    /* background-image: url("../../vids/BowlingBanner_480.gif"); */
    /* background-image: url("../../../public/vids//BowlingHorizontal.mp4"); */
    background-position: center;
    background: black;
}

.banner::after{
    content: "";
    background-image: url("../../images/global/breaker.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    position: absolute;
    bottom: -20px;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 90px;    
}

.banner video{
    position: absolute;
    top: 0%;
    /* left:0;
    right:0; */
    /* margin: auto; */
    width: 100%;
    height: 100%;
    /* text-align: center; */
    /* right: 0; */
    display: flex;
    /* justify-content: center;
    align-items: center; */
    /* overflow: hidden; */
}

.big{
    /* background: green; */
    width: 100%;
    height: 50%;
    position: relative;
    top: 0;
	left: 0;
	z-index: 0;
	overflow: hidden;
}

.banner ul{
    position: relative;
    width: 100%;
    list-style-type: none;
    text-align: center;
    /* top: 30%; */
    letter-spacing: -2px;
    text-transform: uppercase;
    font-size: 3em;
    font-weight: 800;
}

.pageTitle{
    position: absolute;
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 500;
    z-index: 3;
    transform: scale(1.2);
    opacity: 1;
    transition: 1s ease;
}

.pageTitleHidden{
    transform: scale(0);
    opacity: 0;
}

/*****************************
*	vertical news ticker
******************************/
:root{
    --line-size: 1em;
}

.tickerWrapperV{
	display: flex;	
	position: relative;
	overflow: hidden;
    top: 55%;
    height: 3em;
}

.tickerWrapperV .heading{
	background-color: #1c6547;
	color: #fff;
	flex: 0 0 auto;
	z-index: 1000;
}

.tickerWrapperV .heading:after{
	content: "";
	position: absolute;
	top: 0;
}

.newsTickerV{
	display: flex;
	flex-wrap: wrap;
	margin:0;
	padding: 0;
	z-index: 3;
	animation: tic-v 6s cubic-bezier(1, 0, .5, 0) infinite;
}

.newsTickerV:hover { 
	animation-play-state: paused; 
}

.newsTickerV li{
	width: 100%;
	line-height: var(--line-size);
	white-space: nowrap;
    /* todo text-shadow:0px 0px 0 rgb(137,156,213),1px 1px 0 rgb(129,148,205),2px 2px 0 
    rgb(120,139,196),3px 3px 0 rgb(111,130,187),4px 4px 0 rgb(103,122,179),5px 5px 0 
    rgb(94,113,170),6px 6px 0 rgb(85,104,161),7px 7px 0 rgb(76,95,152),8px 8px 0 rgb(68,87,144),9px 9px 0 
    rgb(59,78,135),10px 10px 0 rgb(50,69,126),11px 11px 0 rgb(42,61,118),12px 12px 0 rgb(33,52,109),13px 13px 0 
    rgb(24,43,100),14px 14px 0 rgb(15,34,91),15px 15px 0 rgb(7,26,83),16px 16px 0 rgb(-2,17,74),17px 17px 0 
    rgb(-11,8,65),18px 18px 0 rgb(-19,0,57),19px 19px 0 rgb(-28,-9,48), 20px 20px 0 rgb(-37,-18,39),21px 21px 20px 
    rgba(0,0,0,1),21px 21px 1px rgba(0,0,0,0.5),0px 0px 20px rgba(0,0,0,.2);
 */
}

@keyframes tic-v {
	0%   {margin-top: 0;}
	33%  {margin-top: calc(var(--line-size) * -1);}
	66%  {margin-top: calc(var(--line-size) * -2);}
    100%  {margin-top: calc(var(--line-size) * -3);}
}



/* IPAD  */
@media (min-width: 46rem) {
    .banner{

    }
    
    .banner video{
        position: absolute;
        top: -50%;
        width: 100%;
        height: auto;
        display: flex;
    }

    .big video{
        position: absolute;
        top: 0%;
        width: 100%;
        height: auto;
        display: flex;
    }


    .big{
        /* background: green; */
        width: 100%;
        height: 75%;
        position: relative;
        top: 0;
        left: 0;
        z-index: 0;
        overflow: hidden;
    }

    .tickerWrapperV{
        display: flex;	
        position: relative;
        overflow: hidden;
        top: 45%;
        height: 3em;
    }

    .banner ul{
        font-size: 4em;
    }

    .tickerWrapperV{
        height: 4em;
    }
    
}

@media (min-width: 83rem) {
    .banner video{
        position: absolute;
        top: -100%;
        width: 100%;
        height: auto;
        display: flex;
    }

    .big video{
        position: absolute;
        top: -10%;
        /* left:0;
        right:0; */
        /* margin: auto; */
        width: 100%;
        height: auto;
        /* height: 100%; */
        /* text-align: center; */
        /* right: 0; */
        display: flex;
        /* justify-content: center;
        align-items: center; */
        /* overflow: hidden; */
    }

}