.footer{
    margin-top: 30px;
    width: 100%;
    background-color: rgba(0,0,0,.2);
    display: inline-block;
    text-transform: uppercase;
    padding: 5px 0 0 10px;
    text-align: center;
}

.footer::before{
    content: "";
    position: fixed;
    height:100vh;
    background: red;
}

.footer a, .footer p{
    color: var(--font-secondary);
    display: inline-block;
    padding-left: 15px;
    text-align: center;
    text-decoration: none;
    font-size: .8em;
}

.footer a{
    color: var(--blue-color);
}